<template>
    <div class="rank-container" v-if="showPage">
        <span class="rule" @click="showRules">活动规则</span>

        <div class="linear-green relative pb-9-vw">
            <img class="block" src="./image/cat-bg.png" />
            <div class="absolute ph-15">
                <img class="block" src="./image/rank-banner.png"  />
            </div>
        </div>

        <div v-if="rankList.length" class="rank-wrap" >
            <h2 class="label">已获得猫粮的{{ zoonName }} <strong>（{{count}}/20）</strong></h2>
            <div class="list">
                <div v-for="(item, index) in rankList" :key="index" class="item flex justify-between" >
                    <div class="flex cell-lt">
                        <div class="logo" :style="`background-image: url(${item.pet_image});`"></div>
                        <div class="info flex-column">
                            <span class="name">{{ item.pet_name }}</span>
                            <span class="desc">{{ itemDesc }}</span>
                        </div>
                    </div>
                    <button class="btn" @click="onCheck(item)">点击查看</button>
                </div>
            </div>
        </div>

        <footer class="footer" @click="goHome">帮助更多宠物</footer>

        <ModalRule :visible.sync="ruleVisible" />
    </div>
</template>

<script>
import saUtils from 'sensors-data-utils';
import { shareCtrl, tracking, utils } from '@/common/js/mmcModule';
import ModalRule from '../apply/guize.vue';
import api from './api'
const pageKey = 'h5_jsb_onepoint_ranklist';
const urlParams = utils.getRequestParams();

export default {
    name: 'RankList',
    components: {
        ModalRule
    },
    data() {
        return {
            ruleVisible: false,
            rankList: [],
            count: 0,
            total: 0,
            group_type: Number(urlParams.group_type) || 1,
            showPage: false
        }
    },

    computed: {
        zoonName() {
            let name = ''
            if(this.group_type === 1) {
                name = '小猫'
            } else {
                name = '小狗'
            }
            return name
        },
        itemDesc() {
            let name = ''
            if(this.group_type === 1) {
                name = '猫粮'
            } else {
                name = '狗粮'
            }
            return `已获得一袋${name}`
        },
    },
    created() {
        showLoading('加载中...');
        this.getList()

        shareCtrl.init({
            title: '【宠物筹】1分钱抢129元卫仕宠物口粮活动排行榜',
            imgUrl: `https://m.jisuchou.net/fund/onepoint-share.jpg`,
            desc: '点击查看',
            url: `https://m.jisuchou.net/fund/activity/onepoint/rankList?group_type=${urlParams.group_type}`,
            titleMoment: '【宠物筹】1分钱抢129元卫仕宠物口粮活动排行榜',
            shareRecord: {
                scene: pageKey,
            },
        });

        tracking.init();
        saUtils.init(pageKey);
        let pageName = ''
        if(this.group_type === 1) {
            pageName = 'cat'
        }
        if(this.group_type === 2) {
            pageName = 'dog'
        }
        saUtils.update({
            page_name: pageName
        })
        saUtils.pv();
    },

    methods: {
        showRules() {
            this.ruleVisible = true
        },
        getList() {
            api.getRankList({
                group_type: this.group_type
            }).then(res => {
                hideLoading()
                if(res.code === 0) {
                    this.rankList = res.data.list ? res.data.list : []
                    this.total = res.data.total
                    this.count = res.data.count
                }
                this.showPage = true
            }).catch(() => {
                hideLoading()
                this.showPage = true
            })
        },
        onCheck(item) {
            location.href = '/fund/activity/onepoint/detail?id=' + item.penny_pet_id
        },
        goHome() {
            location.href = '/fund/home?rc=button.p.20230919.onepointranklist.chongwu'
        }
    }
}
</script>

<style lang="less" scoped>
.rank-container {
    background: #F9F9F9;
    min-height: 100vh;
    padding-bottom: 60px;
    box-sizing: border-box;
    .linear {
        background-image: linear-gradient(180deg, #6DC53A 18%, rgba(255,255,255,0.36) 60%, rgba(255,255,255,0.00) 100%);
    }
    .block {
        display: block;
    }
    .rule {
        position: fixed;
        right: 0;
        top: 12px;
        background: #40AE00;
        border-radius: 50px 0 0 50px;
        width: 60px;
        height: 20px;
        line-height: 20px;
        font-size: 12px;
        color: #fff;
        z-index: 10;
    }
    .relative {
        position: relative;
    }
    .absolute {
        position: absolute;
        left: 0;
        bottom: 0;
    }
    .pb-9-vw {
        padding-bottom: 9vw;
    }
    .pb-35 {
        padding-bottom: 35vw;
    }
    .ph-15 {
        padding: 0 15px;
    }

    .flex {
        display: flex;
    }

    .rank-wrap {
        background: #FFFFFF;
        padding: 20px;
        margin-top: 20px;
        text-align: left;
    }
    .label {
        font-family: PingFangSC-Medium;
        font-size: 16px;
        color: #333333;
        margin-bottom: 20px;
        strong {
            color: #FE771B;
        }
    }
    .flex-column {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .item {
        margin-bottom: 20px;
        align-items: center;
        &:last-child {
            margin-bottom: 0;
        }
        .logo {
            width: 60px;
            height: 60px;
            margin-right: 15px;
            border-radius: 5px;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 100%;
        }
    }
    .justify-between {
        justify-content: space-between;
    }
    .name {
        font-size: 16px;
        color: #333333;
        font-weight: bold;
    }
    .btn {
        background: #6DC53A;
        border-radius: 16px;
        font-size: 14px;
        color: #FFFFFF;
        width: 84px;
        height: 32px;
        line-height: 32px;
        display: block;
        border: none;
    }
    .footer {
        position: fixed;
        background: #FE771B;
        height: 50px;
        line-height: 50px;
        font-size: 18px;
        color: #FFFFFF;
        left: 0;
        bottom: 0;
        z-index: 10;
        width: 100%;
        display: block;

    }
}

</style>
