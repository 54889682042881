import { mmcAjax } from '@/common/js/mmcModule';

/**
 * @description: 排行榜
 * @param {type} 1:猫组 2:狗组
 * @return: Promise
 * @Date: 2019-08-23 15:16:12
 */

function getRankList(params) {
    return mmcAjax({
        method: 'get',
        url: `${window.APIBasePath}mmc/penny/rank_list/${params.group_type}`,
        crossDomain: true,
    })
}
export default {
    getRankList
}
